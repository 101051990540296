#menutop {
  margin: 10px;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
  width: auto;
  padding: 0;
  margin-bottom: 0;

  position: fixed; // before

  color: white;
  mix-blend-mode: difference;

  top: 0;
  left: 0;
  //white-space: nowrap;

  .dropdown-content {
    display: none;
    position: absolute;
  }

  .dropbtn:hover .dropdown-content {
    display: block;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    position: absolute; // after
    max-width: 75%;

    //width: 70%;
    //display: block;
  }

  li {
    display: inline-block;
    font-size: 1.5vw;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    /*     border-radius: 50%; */
    margin-right: 0.5vw;
    margin-left: 0.5vw;

    @media screen and (max-width: 768px) {
      font-size: 4vw;
      //display: block; //vertical menu

      /* display: block;
      margin-left: 1vw;
      margin-bottom: 0.5vw; */
    }

    a {
      //color: var(--grey05);
      color: white;
    }

    /*    .active a {

      border: 1px solid var(--grey05);
      padding: 1vw;
      padding-right: 2vw;
      padding-left: 2vw;
      border-radius: 50%;
      backdrop-filter: blur(3px);
    } */
  }
}

/* menu bottom
 */

#menubottom {
  margin: 10px;
  transition: all 0.5s ease-in-out;
  z-index: 1000;

  position: fixed;
  padding: 0;
  bottom: 0;
  right: 0;
  // color: var(--grey05);

  color: white;
  mix-blend-mode: difference;

  li {
    display: inline-block;
    font-size: 1.7vw;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    margin-right: 0.5vw;
    margin-left: 0.5vw;

    @media screen and (max-width: 768px) {
      font-size: 5vw;
      /*  display: block;
      text-align: right; */
    }

    a {
      //color: var(--grey05);
      color: white;
    }

    /* &.active a {
      border: 1px solid var(--grey05);
      padding: 1vw;
      padding-right: 2vw;
      padding-left: 2vw;
      border-radius: 50%;
    } */
  }
}

#menubottom a:hover,
#menubottom li:hover,
#menutop li:hover:not(.dropbtn),
#menutop a:hover:not(.dropbtn) {
  transition: all 0.5s ease-in-out;
  -webkit-filter: blur(2px);
  filter: blur(2px);

  @media screen and (max-width: 768px) {
    -webkit-filter: none;
    filter: none;
  }
}
