#product-component-1635511076826,
#product-component-1636537900619,
#product-component-1636538988234,
#product-component-1636546981202,
#product-component-1636547164339,
#product-component-1636547545778,
#product-component-1636547787054,
#product-component-1636548074492,
#product-component-1636548871877,
#product-component-1636549018516,
#product-component-1636549160085,
#product-component-1636549229087,
#product-component-1636549298476,
#product-component-1636549355744,
#product-component-1636549469243,
#product-component-1636549573051,
#product-component-1636549642538,
#product-component-1636549711318,
#product-component-1636549805528,
#product-component-1636549864910,
#product-component-1636549969551,
#product-component-1636550021646,
#product-component-1636550669593,


#product-component-1636550720817,
#product-component-1636550771853,
#product-component-1636550804843,
#product-component-1636550837256,
#product-component-1636550871366,
#product-component-1636550909810,
#product-component-1636550960679,
#product-component-1636551020166,
#product-component-1636551066529,
#product-component-1636551168285,
#product-component-1636551210611,
#product-component-1636551313178,
#product-component-1636551365613,
#product-component-1636551482682,
#product-component-1636552239831,
#product-component-1636552345369,
#product-component-1636552488888,
#product-component-1636552529688,
#product-component-1636552617546,
#product-component-1636552645690,
#product-component-1636552684741,
#product-component-1636727484277,
#product-component-1636727581199,
#product-component-1636727637456,
#product-component-1636727724924,
#product-component-1636727771915,
#product-component-1637157371678,
#product-component-1637158123816,
#product-component-1668249182367,
#product-component-1637158367968,
#product-component-1637159051821,
#product-component-1637159226472,
#product-component-1637159337685,
#product-component-1637159473311,
#product-component-1645191917362,
#product-component-1645192078451,
#product-component-1645192194481,
#product-component-1645192304866,
#product-component-1645192373977,
#product-component-1645192417498,
#product-component-1645192483439,
#product-component-1645192531968,
#product-component-1645192583687,
#product-component-1645192638189,
#product-component-1645192690865,
#product-component-1645192731886,
#product-component-1645192784123,
#product-component-1645192824142,
#product-component-1645192863655,
#product-component-1645192919736,
#product-component-1645193144144,
#product-component-1645193229382,
#product-component-1645193275837,
#product-component-1645193351842,
#product-component-1645193388896,
#product-component-1645193430072,
#product-component-1645193501168,
#product-component-1645193543328,
#product-component-1645193577673,
#product-component-1645193618335,
#product-component-1645193659507,
#product-component-1645193700585,
#product-component-1645193832674,
#product-component-1645193869902,
#product-component-1645193919264,
#product-component-1645193961173,
#product-component-1645194021366,
#product-component-1645194088242,
#product-component-1645194149790,
#product-component-1645194178901,
#product-component-1645194225421,
#product-component-1645194263374,
#product-component-1645194303174,
#product-component-1645194920505,
#product-component-1645195303558,
#product-component-1667310605507,
#product-component-1667315288430,
#product-component-1667315546956,
#product-component-1667316788069,
#product-component-1667317447730,
#product-component-1667319620030,
#product-component-1667315884048,
#product-component-1667316192181,
#product-component-1667317162510,
#product-component-1667317943490,
#product-component-1667318565191,
#product-component-1667319170730,
#product-component-1667319899086,
#product-component-1667321584183,
#product-component-1667320380103,
#product-component-1667320904994,
#product-component-1667321194030,
#product-component-1667316545374,
#product-component-1667318199953,
#product-component-1667320177789,
#product-component-1667320641429,
#product-component-1673345094901,
#product-component-1673344798019,
#product-component-1673345356619,
#product-component-1673345668558,
#product-component-1673346069324,
#product-component-1673346238052,
#product-component-1673346451929,
#product-component-1673346722311,
#product-component-1673346992154,
#product-component-1673353951788,
#product-component-1673354119696,
#product-component-1673354325396,
#product-component-1673354529413,
#product-component-1673354712912,
#product-component-1673354891832,
#product-component-1673356716747,
#product-component-1673356936274,
#product-component-1673357167109,
#product-component-1673357523481,
#product-component-1673357749508,
#product-component-1673357918278,
#product-component-1673357749508,
#product-component-1673691429794,
#product-component-1673691771462,
#product-component-1673691956792,
#product-component-1699472032623,
#product-component-1699472228712,
#product-component-1699472398258,
#product-component-1699472570034,
#product-component-1699472659251,
#product-component-1699472795672,
#product-component-1699473046154,
#product-component-1699473225991,
#product-component-1699473390962,
#product-component-1699473649120,
#product-component-1699473742134,
#product-component-1699473843631,
#product-component-1699473997560,
#product-component-1702411036277,
#product-component-1702411233152,
#product-component-1702411860143,
#product-component-1702411943796,
#product-component-1702412063375,
#product-component-1702412247045,
#product-component-1702412460123,
#product-component-1702412593864,
#product-component-1702412746744,
#product-component-1702412877091,
#product-component-1702412958904,
#product-component-1702413093810,
#product-component-1702413245928,
#product-component-1702413689184,
#product-component-1702413793101,
#product-component-1702413901321,
#product-component-1702414021712,
#product-component-1702414113407,
#product-component-1716374938558, // end AW23

#product-component-1699472032623, // start SS24
#product-component-1714493001349,
#product-component-1714493636193,
#product-component-1714494006861,
#product-component-1714494250947,
#product-component-1714495409372,
#product-component-1714495148247,
#product-component-1714496302548,
#product-component-1714496600019,
#product-component-1714496813673,
#product-component-1714497195897,
#product-component-1714547999633,
#product-component-1714548281654,
#product-component-1714548544580,
#product-component-1714548875384,
#product-component-1714549807284,
#product-component-1714550233803,
#product-component-1714550491627,
#product-component-1714551005906,
#product-component-1714658796396, 
#product-component-1714551640195,
#product-component-1714551850415,
#product-component-1714552134704,
#product-component-1714552271864,
#product-component-1714552638686,
#product-component-1714552889512,
#product-component-1715011646267 {
  z-index: 4999;
  position: fixed;
  top: 52%;
  left: 1%;
  transform: translate(0, -52%);
  width: 7vw;

  @media screen and (max-width: 768px) {
    width: 22vw;
    top: 50%;
    left: 3%;
    transform: translate(0, -50%);
  }
}

/* .shopify-buy__option-select__select {
  width: fit-content;
} */

/* @media screen and (max-width: 768px) {
  #product-component-1635511076826,
  #product-component-1636537900619,
  #product-component-1636538988234,
  #product-component-1636546981202,
  #product-component-1636547164339,
  #product-component-1636547545778,
  #product-component-1636547787054,
  #product-component-1636548074492,
  #product-component-1636548871877,
  #product-component-1636549018516,
  #product-component-1636549160085,
  #product-component-1636549229087,
  #product-component-1636549298476,
  #product-component-1636549355744,
  #product-component-1636549469243,
  #product-component-1636549573051,
  #product-component-1636549642538,
  #product-component-1636549711318,
  #product-component-1636549805528,
  #product-component-1636549864910,
  #product-component-1636549969551,
  #product-component-1636550021646,
  #product-component-1636550669593,
  #product-component-1636550720817,
  #product-component-1636550771853,
  #product-component-1636550804843,
  #product-component-1636550837256,
  #product-component-1636550871366,
  #product-component-1636550909810,
  #product-component-1636550960679,
  #product-component-1636551020166,
  #product-component-1636551066529,
  #product-component-1636551168285,
  #product-component-1636551210611,
  #product-component-1636551313178,
  #product-component-1636551365613,
  #product-component-1636551482682,
  #product-component-1636552239831,
  #product-component-1636552345369,
  #product-component-1636552488888,
  #product-component-1636552529688,
  #product-component-1636552617546,
  #product-component-1636552645690,
  #product-component-1636552684741,
  #product-component-1636727484277,
  #product-component-1636727581199,
  #product-component-1636727637456,
  #product-component-1636727724924,
  #product-component-1636727771915,
  #product-component-1637157371678,
  #product-component-1637158123816,
  #product-component-1637158367968,
  #product-component-1637159051821,
  #product-component-1637159226472,
  #product-component-1637159337685,
  #product-component-1637159473311,
  #product-component-1645191917362,
  #product-component-1645192078451,
  #product-component-1645192194481,
  #product-component-1645192304866,
  #product-component-1645192373977,
  #product-component-1645192417498,
  #product-component-1645192483439,
  #product-component-1645192531968,
  #product-component-1645192583687,
  #product-component-1645192638189,
  #product-component-1645192690865,
  #product-component-1645192731886,
  #product-component-1645192784123,
  #product-component-1645192824142,
  #product-component-1645192863655,
  #product-component-1645192919736,
  #product-component-1645193144144,
  #product-component-1645193229382,
  #product-component-1645193351842,
  #product-component-1645193388896,
  #product-component-1645193430072,
  #product-component-1645193501168,
  #product-component-1645193543328,
  #product-component-1645193577673,
  #product-component-1645193618335,
  #product-component-1645193659507,
  #product-component-1645193700585,
  #product-component-1645193832674,
  #product-component-1645193869902,
  #product-component-1645193919264,
  #product-component-1645193961173,
  #product-component-1645194021366,
  #product-component-1645194088242,
  #product-component-1645194149790,
  #product-component-1645194178901,
  #product-component-1645194225421,
  #product-component-1645194263374,
  #product-component-1645194303174,
  #product-component-1645194920505,
  #product-component-1645195303558,
  #product-component-1667310605507,
  #product-component-1667315288430,
  #product-component-1667315546956,
  #product-component-1667316788069,
  #product-component-1667317447730,
  #product-component-1667319620030,
  #product-component-1667315884048,
  #product-component-1667316192181,
  #product-component-1667317162510,
  #product-component-1667317943490,
  #product-component-1667318565191,
  #product-component-1667319170730,
  #product-component-1667319899086,
  #product-component-1667321584183,
  #product-component-1667320380103,
  #product-component-1667320904994,
  #product-component-1667321194030,
  #product-component-1667316545374,
  #product-component-1667318199953,
  #product-component-1667320177789,
  #product-component-1667320641429 {
    width: 15vw;
    top: 50%;
    left: 3%;
    transform: translate(0, -50%);
  }
} */
