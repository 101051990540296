.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  /*  background-color: rgba(243, 14, 14, 0.193);
  background-color: rgba(255, 255, 255, 0.114); */
  // backdrop-filter: blur(25px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure it's above other content */
  width: 100%;
  height: 100%;
}

.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.309);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;

  mix-blend-mode: unset;
  width: 40%;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  h4 {
    //font-family: "ABCFavoritLightSansSerif", sans-serif;
    position: relative;
    left: 0;
    //width: fit-content;
    width: 90%;
    color: rgb(58, 58, 58);
    text-align: left;

    @media screen and (max-width: 768px) {
      font-size: 2vw;
    }
  }

  form {
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.198);

    width: 100%;
    display: grid;
    grid-template-columns: 8fr 1fr;
    gap: 10px;

    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    button {
      font-size: 1vw;
      color: black;

      mix-blend-mode: exclusion;
      background: transparent;
      transition: 1s;
      cursor: pointer;

      border: 1px solid white;
      height: 75%;
      align-self: center; /* This will vertically center the button */

      padding: 1vw;
      /*   padding-right: 1vw;
      padding-left: 2vw; */
      border-radius: 50%;

      &:hover {
        -webkit-filter: blur(2px);
        filter: blur(2px);
      }

      @media screen and (max-width: 768px) {
        font-size: 2vw;
        //height: 60%;
        height: 100%;
        background-color: var(--backgroundColor);
      }
    }
  }
}

.modal-close-button {
  position: relative;
  float: right;

  background: none;
  font-size: 1vw;
  cursor: pointer;
  color: rgb(60, 60, 60);
  border: 1px solid;
  border-radius: 50%;
  padding: 0.2vw;
  padding-left: 0.4vw;
  padding-right: 0.4vw;

  transition: 1s;

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
}

// mailchimp

//subscribe button
.optionalParent {
  input {
    font-size: 1vw;
    color: black;

    mix-blend-mode: exclusion;
    background: transparent;
    transition: 1s;
    cursor: pointer;

    border: 1px solid white;
    height: 100%;
    align-self: center; /* This will vertically center the button */

    padding: 1vw;
    /*   padding-right: 1vw;
      padding-left: 2vw; */
    border-radius: 50%;

    &:hover {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    @media screen and (max-width: 768px) {
      font-size: 2vw;
      //height: 60%;
      //height: 100%;
      background-color: var(--backgroundColor);
    }
  }
}

//email input
.mc-field-group {
  input {
    font-size: 1.2vw;
    //background: transparent;
    //background: rgba(255, 255, 255, 0.309);
    border: none;
    -webkit-backdrop-filter: var(--backdropFilterBlur);
    backdrop-filter: var(--backdropFilterBlur);
    background-color: var(--backgroundColor);
    width: 100%;
    height: 100%;
    /*  padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px; */

    @media screen and (max-width: 768px) {
      font-size: 3vw;
      background-color: var(--backgroundColor);

      padding-left: unset;
      padding-top: unset;
      padding-bottom: unset;
    }

    &:hover {
      background-color: var(--backgroundColorLighter);
    }

    &:focus {
      background-color: var(--backgroundColorLighter);
    }

    &[type="text"] {
      font-family: "Roboto", sans-serif;
    }
  }
}
