// chart image

.sizechart {
  /*   max-width: 50vw;
  max-height: 50vh; */
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*   box-shadow: var(--boxshadow01);
 */
  z-index: 2000;
  transition-duration: 0.7s;
  -webkit-animation-name: imgopening;
  animation-name: imgopening;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;

  @media screen and (max-width: 768px) {
    z-index: 10000;
  }

  img {
    // width: 100%; // Make the image width fit the container width
    height: auto; // Maintain the aspect ratio of the image
    max-width: 50vw; // Subtract padding from the width
    max-height: 50vh; // Subtract padding from the height
    display: block; // Remove any extra space below the image
    border: 1px black solid;
    padding: 25px;
    background-color: white;
    box-sizing: border-box; // Include padding and

    @media screen and (max-width: 768px) {
      max-width: 80vw;
    }
  }

  p.close {
    position: fixed;
    top: 2%;
    right: 1%;

    background: none;
    font-size: 1vw;
    cursor: pointer;
    color: rgb(60, 60, 60);
    border: 1px solid;
    border-radius: 50%;
    padding: 0.2vw;
    padding-left: 0.4vw;
    padding-right: 0.4vw;

    transition: 1s;

    &:hover {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    @media screen and (max-width: 768px) {
      font-size: 3vw;
    }
  }
}

// chart button left of screen
// INSIDE ShopifyMainComponent.js

#sizechartbutton {
  position: fixed;
  top: -55%;
  left: 50%;
  transform: translateX(-50%);
  //top: -55%;
  white-space: nowrap;
  font-size: 15px;
  // width: fit-content;
  //color: var(--grey05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  //width: 7vw;
  // width: 5vw;

  padding: 5px;
  color: white;
  background-color: black;
  border-radius: 5px;

  text-align: center;

  /*   position: fixed;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  padding: 5px;
  background-color: #000000;
  font-family: Arial;
  color: #ffffff;
  border-radius: 5px;
  text-align: center; */

  &:hover {
    transition: all 0.25s ease-in-out;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 22vw;
  }

  /* .active {
    background-color: white;
  } */
}

.button__blur {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
