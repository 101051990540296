.button-arrow-up {
  position: fixed;
  bottom: 1%;
  left: 1%;
  font-size: 1.5vw;
  //color: var(--grey05);

  z-index: 10000;
  transition: var(--transition);

  color: white;
  mix-blend-mode: difference;

  //display: none;

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    font-size: 4vw;
    display: block;
  }
}
