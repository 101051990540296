#buttons__collection {
  position: fixed;
  bottom: 1%;
  left: 1%;
  display: flex;
  flex-direction: row;
  z-index: 10000;
  color: white;
  mix-blend-mode: difference;
}

.button__video,
.button__pdf {
  /*   position: fixed;
  bottom: 1%;
  left: 1%; */
  font-size: 1.5vw;
  //color: var(--grey05);

  //z-index: 10000;
  transition: var(--transition);

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}
