.backdrop {
  backdrop-filter: blur(50px);
}

.fullcenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
  font-size: 4vw;
}

.bold {
  font-weight: bold;
}

.circle {
  //border: 1px solid var(--grey05);
  border: 1px solid white;

  padding: 1vw;
  padding-right: 2vw;
  padding-left: 2vw;
  border-radius: 50%;
  /*  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
}

.rectangle {
  border: 1px solid var(--grey05);
  padding: 0.5vw;
  font-size: 1vw;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  cursor: pointer;
  /*   backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */

  /*  a {
    border: 1px solid var(--grey05);
    padding: 0.5vw;
    font-size: 1vw;
  } */
}

.circle_small {
  border: 1px solid var(--grey05);
  padding: 0.3vw;
  border-radius: 50%;
}

.serif {
  font-family: "ABCArizonaSerif";
}

.blur {
  filter: blur(10px);
  transition: 1s;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

@media screen and (max-width: 768px) {
  .remove {
    display: none;
  }
}

/* .verticalcentering {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .verticalcentering img {
    height: 50vh;
  }
  
  .upcentering {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  
  .upcentering img {
    height: 30vh;
  }
 */
