#detailsClothingContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*   display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  height: auto;
  z-index: 2000;
  background-color: white;
  padding: 50px;

  display: none;
  border: 1px black solid;

  transition-duration: 0.7s;
  -webkit-animation-name: imgopening;
  animation-name: imgopening;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;

  @media screen and (max-width: 768px) {
    width: 80vw;
    z-index: 10000;
    padding: 50px;
  }

  p.detailsClothing {
    font-size: 1.5vw;

    @media screen and (max-width: 768px) {
      font-size: 3vw;
    }
  }

  p.close {
    position: fixed;
    top: 2%;
    right: 1%;

    background: none;
    font-size: 1vw;
    cursor: pointer;
    color: rgb(60, 60, 60);
    border: 1px solid;
    border-radius: 50%;
    padding: 0.2vw;
    padding-left: 0.4vw;
    padding-right: 0.4vw;

    transition: 1s;

    &:hover {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    @media screen and (max-width: 768px) {
      font-size: 3vw;
    }
  }
}

#detailsClothingButton {
  z-index: 4999;
  position: fixed;

  left: 4.5%;
  top: 60%;
  transform: translateX(-50%);
  //top: -55%;
  white-space: nowrap;
  font-size: 15px;
  // width: fit-content;
  //color: var(--grey05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  padding: 0.2vw;
  padding-left: 0.4vw;
  padding-right: 0.4vw;
  color: white;
  background-color: black;
  border-radius: 5px;

  text-align: center;

  &:hover {
    transition: all 0.25s ease-in-out;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 22vw;
    left: 14%;
    padding: 1vw;
    top: 58%;
  }
}
