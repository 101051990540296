/* home */

.videoindex {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  @media screen and (max-width: 768px) {
  }
}
