.videoscollection {
  /*  display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    transition-duration: 0.7s;
    -webkit-animation-name: imgopening;
    animation-name: imgopening;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; */
  z-index: 99;
  display: none;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
