// description sentence

.text {
  position: absolute;
  bottom: 5px;
  left: 5px;
  margin: 5px;
  padding: 2px;
  color: var(--grey05);
  font-size: 1.3vw;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}

// sold out or not

.sold-out {
  position: absolute;
  top: 5px;
  left: 5px;
  margin: 5px;
  padding: 2px;

  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 1.2vw;
  z-index: 1000;
  mix-blend-mode: unset;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}

// double picture hovering
/* 
.content {
  position: relative;
}

.content img {
  transition: opacity 1s ease-in-out;
}

.content img.img-bottom:hover {
  opacity: 0;
}

.content .img-bottom {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.content:hover .img-bottom {
  display: inline;
}
 */
