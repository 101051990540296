.pagenotfound {
  position: fixed;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "ABCFavoritLightSansSerif";
  text-align: center;

  img {
    max-width: 35vw;
    transition: all 0.25s ease-in-out;

    &:hover {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }
  }
}
