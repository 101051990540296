.backdropblur,
.backdropblur02 {
  z-index: 500;
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: none;
}
