#clothing {
  display: grid;
  /* gap: 10px; */
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  width: 100vw;
  margin: 0;
  padding: 0;
  padding-bottom: 3vw;

  position: absolute;
  //position: relative; // new

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    transition: transform 0.25s linear;
    width: 100vw;
  }

  div {
    transition: 1s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    vertical-align: text-bottom; /* bottom white space */
  }
}

.content {
  position: relative;
}

.content img {
  transition: opacity 1s ease-in-out;
}

.content img.img-bottom:hover {
  opacity: 0;
}

.content .img-bottom {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.content:hover .img-bottom {
  display: inline;
}

#up {
  top: 0;
  position: absolute;
}
