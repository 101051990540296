#shipping-return-container {
  //position: fixed;
  position: absolute;
  top: 10%;
  width: 45%;
  /*   height: auto; */
  display: flex;
  flex-direction: column;

  left: 50%;
  transform: translate(-50%, 0%);
  overflow-y: auto;
  padding-bottom: 5%;

  @media screen and (max-width: 768px) {
    width: 85%;
    padding-bottom: 10%;
  }

  p {
    font-size: 1vw;
    text-align: left;

    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }
  }
}
