@keyframes imgopening {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes imgopening {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

///////
///
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation ease-in-out 5s infinite;
  animation: flickerAnimation ease-in-out 5s infinite;
}

.flicker-loader {
  -webkit-animation: flickerAnimation ease-in-out 3s infinite;
  animation: flickerAnimation ease-in-out 3s infinite;
}
