.discount__message {
  position: fixed;
  color: var(--grey05);
  text-align: center;
  font-size: 1.5vw;
  height: auto;
  z-index: 1500;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  border: 1px solid;
  /*   background-color: #fcfcfc; */
  /*   backdrop-filter: blur(3px);
 */
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 50%;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
    width: 80%;
    top: 10%;
    padding: 10px;
  }
}
