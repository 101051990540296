#connect {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 3vw;
  width: 100%;
  color: var(--color05);
  z-index: 1;

  @media screen and (max-width: 768px) {
    max-width: 80%;
    max-height: 80%;
    font-size: 5vw;
  }

  a:hover {
    border: 1px solid var(--grey03);
    padding: 1vw;
    padding-right: 2vw;
    padding-left: 2vw;
    border-radius: 50%;
  }
}

#credit {
  position: fixed;
  top: 2%;
  left: 2%;
  font-size: 1.5vw;
  transition: all 0.5s ease-in-out;

  mix-blend-mode: difference;
  text-align: center;
  a {
    color: white;
  }

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
}
