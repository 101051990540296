/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
 */
/* @font-face {
  font-family: "ABCFavoritLightSansSerif";
  src: url("../content/fonts/ABCFavorit-Light.otf") format("opentype");
}

@font-face {
  font-family: "ABCArizonaSerif";
  src: url("../content/fonts/ABCArizonaSerif-Light.woff") format("woff"),
    url("../content/fonts/ABCArizonaSerif-Light.woff") format("truetype");
} */

/* @font-face {
  font-family: "ABCFavoritLightSansSerif";
  src: url("./ABCFavorit-Light.otf") format("opentype");
} */

@font-face {
  font-family: "ABCFavoritLightSansSerif";
  src: url("./ABCFavorit-Light.woff2") format("woff2"),
    url("./ABCFavorit-Light.woff") format("woff");
}

@font-face {
  font-family: "ABCArizonaSerif";
  src: url("./ABCArizonaSerif-Light.woff") format("woff"),
    url("./ABCArizonaSerif-Light.woff") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:root {
  --grey01: rgb(251, 251, 251);
  --grey02: rgb(249, 249, 249);
  --grey03: #9b9b9b;
  --grey04: #c3c3c3;
  --grey05: #474747;
  --color05: #7a7a7a;
  --color06: #ff0000;
  --boxshadow01: 0px 0px 48px 3px rgba(0, 0, 0, 0.04);
  --zindexmax: 99999;
  --eshop: #fafafa;
  --collections: #222222;
  --transition: all 0.25s ease-in-out;

  --bodyColor: #ffffff;
}

body,
html {
  height: 100%;
  width: 100%;
  //font-family: "Roboto";
  font-family: "ABCFavoritLightSansSerif";

  font-weight: 300;
  background-color: var(--bodyColor);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overscroll-behavior-x: contain;
}

/* body,
html {
  

  overflow-x: hidden;
}
 */
/* body {
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    background-color: var(--eshop);
    
  } */

/* img:not(.logo):not(.sizechart):not(#clothing) {
  object-fit: cover;
  pointer-events: none;
  min-height: 100vh;
  height: 100vh;
  display: inline-block;
} */

body::-webkit-scrollbar {
  /* width: 8px; */
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: rgb(211, 211, 211);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
  border-radius: 10px;
}

a {
  text-decoration: none;
  color: var(--grey05);
  cursor: pointer;
}

h1 {
  font-size: 1.5vw;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    font-size: 3.5vw;
  }
}

::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}

main {
  width: 100%;
  height: 100%;
  position: absolute;
}
