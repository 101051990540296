.logo {
  z-index: 5001;
  width: 11vw;
  top: 1.5%;
  right: 1%;
  position: fixed;
  mix-blend-mode: difference;

  @media screen and (max-width: 768px) {
    width: 25vw;
    right: 1.5%;
    top: 1.3%;
    /*  transform: rotate(90deg);
    transform-origin: top right; */
  }

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
    transition: all 0.5s ease-in-out;
  }
}

/* transform: rotate(90deg);
transform-origin: 50% 50%;

 */
