.textshop {
  position: fixed;
  //color: var(--grey05);
  text-align: center;
  font-size: 2vw;
  height: auto;
  z-index: 1000;
  bottom: 5%;
  left: 3%;
  width: auto;
  /*   backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  border: 1px solid;
  padding: 10px;
  border-radius: 50%;

  color: white;
  mix-blend-mode: difference;
  transition: var(--transition);

  @media screen and (max-width: 768px) {
    -webkit-transform: scale(1);
    font-size: 4vw;
    width: 90%;
    text-align: center;
    justify-content: center;

    padding: 10px;
    border-radius: 50%;

    &:hover {
      -webkit-filter: blur(0px);
      filter: blur(0px);
    }
  }

  /*   &:hover {
    -webkit-transform: scale(1);
    font-size: 3vw;
    width: 90%;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      font-size: 4vw;
    }
  } */

  &:hover {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
}
