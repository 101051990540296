.loaderentrance {
  background-color: rgb(0, 0, 0);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
}

.textloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  font-size: 1vw;
  pointer-events: none;
  /* border: 0.5px solid var(--color05); */
  color: var(--color05);
  border-radius: 50%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-right: 2vw;
  padding-left: 2vw;
  mix-blend-mode: exclusion;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
}

.loader.hidden,
.loaderentrance.hidden {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .loader {
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    transition-timing-function: ease-in-out;
  }

  #logohidden {
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    z-index: 99999;
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    transition-timing-function: ease-in-out;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .loader {
    background-color: rgb(247, 247, 247);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    transition-timing-function: ease-in-out;
  }

  #logohidden {
    background-color: rgb(247, 247, 247);
    z-index: 99999;
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    transition-timing-function: ease-in-out;
  }
}
